import { EventEmitter } from '@angular/core';
import React, { FC } from 'react';
import { Trans } from '@lingui/react';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  listItemButtonClasses,
  Typography,
  Sheet,
  GlobalStyles,
  ErrorBoundary,
  KeyboardArrowDownIcon,
  Logo,
  Button,
} from '@startuptools/ui';
import { Badge, IconButton, useTheme } from '@mui/joy';
import { niceEnabled } from '../../../environments/environment';
import {
  DashboardIcon,
  DescriptionIcon,
  GroupsIcon,
  InventoryIcon,
  AssessmentIcon,
  HourglassTopIcon,
  InsightsIcon,
  SettingsIcon,
  LogoutRoundedIcon,
  Stack,
  IconComponent,
} from '@startuptools/ui';
import { useNgRouter } from '../react-wrapper/AngularRouterContext';
import { Menu } from './Menu';
import { closeSidebar } from './utils';
import { switchLang } from '../../helpers/switch-lang';
import {
  useCompanySuspenseQuery,
  useCurrentUserSuspenseQuery,
  useNextShareCapitalChangesSuspenseQuery,
} from '../../graphql/react-operations';
import { useCompanyId } from '@startuptools/common/react-wrapper';

const Toggler = ({
  defaultExpanded = false,
  renderToggle,
  children,
  render,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => React.ReactNode;
  render: boolean;
}) => {
  const [open, setOpen] = React.useState(defaultExpanded);
  if (!render) return renderToggle({ open, setOpen });
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};

const NavItem = ({
  href,
  label,
  icon: Icon,
  children,
  topLevel,
}: {
  href: string;
  label: React.ReactNode;
  icon?: IconComponent;
  children?: React.ReactNode;
  position?: 'top' | 'bottom';
  topLevel?: boolean;
}) => {
  const { ngNavigateByUrl, isActive, pathName, rootPath } = useNgRouter();
  return (
    <ListItem nested={Boolean(children)}>
      <Toggler
        key={pathName} /* reset state on navigation */
        defaultExpanded={isActive(href)}
        render={Boolean(children)}
        renderToggle={({ open, setOpen }) => (
          <ListItemButton
            href={children ? undefined : rootPath + href}
            component={children ? 'button' : 'a'}
            onClick={e => {
              if (e.metaKey || e.ctrlKey || e.shiftKey || e.altKey || e.button === 1) {
                return; // Let the default browser behavior take over
              }
              e.preventDefault();
              if (children) {
                return setOpen(!open);
              }

              void ngNavigateByUrl(href);
              closeSidebar();
            }}
            role="menuitem"
            selected={Boolean(!children && href && isActive(href))}
          >
            {Icon && (
              <Icon
                fontSize="small"
                sx={{
                  /* *this should be inherited automatically, dont get why it doesn't. hard coding this
                  for now to move on.*/
                  color: 'var(--Icon-color, var(--joy-palette-text-icon, var(--joy-palette-neutral-500, #636B74)))',
                  fill: 'var(--Icon-color, var(--joy-palette-text-icon, var(--joy-palette-neutral-500, #636B74)))',
                }}
              />
            )}

            <ListItemContent>
              <Typography level={children || topLevel ? 'title-sm' : 'body-sm'}>{label}</Typography>
            </ListItemContent>
            {Boolean(children) && <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />}
          </ListItemButton>
        )}
      >
        {children && <List sx={{ gap: 0.5 }}>{children}</List>}
      </Toggler>
    </ListItem>
  );
};

const SideNavUi = ({ children, bottomSection }: { children: React.ReactNode; bottomSection: React.ReactNode }) => {
  const theme = useTheme();

  const topItems = (children as React.ReactElement[]).filter(c => c.props.position === 'top' || !c.props.position);
  const bottomItems = (children as React.ReactElement[]).filter(c => c.props.position === 'bottom');

  const { ngNavigateByUrl } = useNgRouter();

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 1000,
        height: '97.5dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={theme => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: { xs: 'calc(4 * var(--joy-spacing))', md: 0 },
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Stack gap={1} justifyContent="center" alignItems="center">
        <Button
          onClick={() => ngNavigateByUrl('/about/dashboard')}
          sx={{
            width: '50%',
            padding: 0,
            background: 'transparent',
            '&:hover': {
              background: 'transparent',
            },
          }}
        >
          <Logo color={theme.palette.primary[500]} />
        </Button>
      </Stack>

      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': theme => theme.vars.radius.sm,
          }}
        >
          {topItems}
        </List>

        <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': theme => theme.vars.radius.sm,
          }}
        >
          {bottomItems}
        </List>
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>{bottomSection}</Box>
    </Sheet>
  );
};

export const bottomItems = [
  {
    title: <Trans id="Settings" />,
    icon: <SettingsIcon />,
    path: ['settings'],
    children: [
      { primary: <Trans id="Board Meetings" />, path: ['board', 'members'] },
      { primary: <Trans id="Users" />, path: ['users'] },
      { primary: <Trans id="Profile" />, path: ['profile'] },
      { primary: <Trans id="Billing" />, path: ['billing'] },
    ],
  },
];

interface Props {
  navigate: EventEmitter<string>;
  showDeleteCompanyDialog: () => void;
}

const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
  return undefined;
};

export const SideNav: FC<Props> = ({ navigate, showDeleteCompanyDialog }) => {
  const companyId = useCompanyId();
  const {
    data: { currentUser: user },
  } = useCurrentUserSuspenseQuery();
  const {
    data: { company },
  } = useCompanySuspenseQuery({ variables: { id: companyId } });
  const {
    data: {
      nextShareCapitalChanges: { inSync },
    },
  } = useNextShareCapitalChangesSuspenseQuery({ variables: { companyId } });

  const imitateUser = getCookie('sut-imitate-user');
  return (
    <SideNavUi
      bottomSection={
        <ErrorBoundary fallback={<></>}>
          <Menu switchLang={switchLang} showDeleteCompanyDialog={showDeleteCompanyDialog} />

          <Box sx={{ minWidth: 0, flex: 1 }}>
            <Typography level="title-sm" noWrap color={imitateUser ? 'danger' : 'neutral'}>
              {user.name}
            </Typography>
            <Typography level="body-xs" noWrap>
              {user.email}
            </Typography>
          </Box>
          <IconButton
            size="sm"
            variant="plain"
            color="neutral"
            onClick={async () => {
              if (imitateUser) {
                await fetch(`/api/imitate-user?userId=`, {
                  credentials: 'same-origin',
                });
                window.location.href = '/';
              }
              await fetch('/api/logout', { method: 'POST' });
              navigate.emit('/auth');
            }}
          >
            <LogoutRoundedIcon />
          </IconButton>
        </ErrorBoundary>
      }
    >
      <NavItem href="/about" icon={DashboardIcon} label={<Trans id="Overview" />}>
        <NavItem href="/about/dashboard" label={<Trans id="Dashboard" />} />
        <NavItem href="/about/company_directors" label={<Trans id="Board & Accountant" />} />
        <NavItem href="/about/cases" label={<Trans id="Cases" />} />
      </NavItem>
      <NavItem href="/documents" icon={DescriptionIcon} label={<Trans id="Files & Documents" />}>
        <NavItem href="/documents/company-documents" label={<Trans id="My Files & Documents" />} />
        <NavItem href="/documents/free-templates" label={<Trans id="Free Templates" />} />
      </NavItem>
      <NavItem href="/board-portal" icon={GroupsIcon} label={<Trans id="Board Portal" />}>
        <NavItem label={<Trans id="Shareholders' Meetings" />} href="/board-portal/shareholders-meetings" />
        <NavItem label={<Trans id="Board Meetings" />} href="/board-portal/board-meetings" />
        <NavItem label={<Trans id="Authorizations" />} href="/board-portal/authorization-groups" />
        <NavItem label={<Trans id="Articles of Association" />} href="/board-portal/articles-of-assocs" />
      </NavItem>
      <NavItem
        href="/share-register"
        icon={(props: React.ComponentProps<IconComponent>) => (
          <Badge
            invisible={inSync}
            color="primary"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <InventoryIcon {...props} />
          </Badge>
        )}
        label={<Trans id="Share Registry" />}
      >
        <NavItem label={<Trans id="Overview" />} href="/share-register/share-capital" />
        <NavItem label={<Trans id="Shareholders" />} href="/share-register/overview" />
        <NavItem
          label={
            <Badge
              invisible={inSync}
              color="primary"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Trans id="Transactions" />
            </Badge>
          }
          href="/share-register/transactions"
        />
        <NavItem label={<Trans id="Share Registry" />} href="/share-register/full" />
        <NavItem label={<Trans id="Individual Reports" />} href="/share-register/individual" />
        <NavItem label={<Trans id="Transaction Reports" />} href="/share-register/transaction" />
        <NavItem label={<Trans id="Share Certificates" />} href="/share-register/share-certificates" />
      </NavItem>
      <NavItem
        href="/cap-table"
        icon={AssessmentIcon}
        topLevel
        label={
          <Badge
            badgeContent={<Trans id="Pro" />}
            slotProps={{
              badge: {
                sx: theme => ({
                  right: '-20px',
                  backgroundColor: theme.palette.pro.dark,
                  color: theme.palette.text.primary,
                }),
              },
            }}
          >
            <Trans id="Cap Table" />
          </Badge>
        }
      />
      <NavItem href="/stock-options" icon={HourglassTopIcon} label={<Trans id="Stock options" />}>
        <NavItem label={<Trans id="Warrants" />} href="/stock-options/warrant-programs" />
        <NavItem label={<Trans id="QESOs" />} href="/stock-options/employee-options" />
      </NavItem>
      <NavItem href="/investments" icon={InsightsIcon} label={<Trans id="Investments" />}>
        <NavItem label={<Trans id="Share Issues" />} href="/investments/share-issues" />
        <NavItem label={<Trans id="WISE" />} href="/investments/wise-options" />
        {company.isAntlerCompany && (
          <NavItem label={<Trans id="Antler-WISE" />} href="/investments/antler-wise-options" />
        )}
        {niceEnabled && user.admin && <NavItem label={<Trans id="NICE" />} href="/investments/nice-convertibles" />}
      </NavItem>
      <NavItem href="/settings" icon={SettingsIcon} label={<Trans id="Settings" />} position="bottom">
        <NavItem label={<Trans id="Board Meetings" />} href="/settings/board/members" />
        <NavItem label={<Trans id="Users" />} href="/settings/users" />
        <NavItem label={<Trans id="Profile" />} href="/settings/profile" />
        <NavItem label={<Trans id="Billing" />} href="/settings/billing" />
      </NavItem>
    </SideNavUi>
  );
};
